import React, { Component } from "react";
import DataTable from "../../components/DataTable/DataTable";
import ScoreInput from "../Abstract/ScoreInput/ScoreInput";
import AbstractScoreSummary from "../Abstract/AbstractScoreSummary/AbstractScoreSummary";
import { Alert, Services } from "../Service/Services";
import axios from "../../axios";
import Loader from "../../components/BoxContainer/Loader";

class SuperReviewerIndex extends Component {
  constructor(props) {
    super(props);

    this.data_table_element = React.createRef();
    this.abstract_score_summary_element = React.createRef();
  }

  state = {
    data: {},
    title: "",
    subtitle: "",
    loading: false,
    is_allow_to_review: false,
    round: 0,
  };

  scoreSelectedItems = (score, selected_items) => {
    const listAbstractNo = selected_items.map((m) => m.abstractNo).join(", ");
    Alert.confirm(
      `รหัสผลงาน ${listAbstractNo} จะถูกกำหนดเป็น ${score} คะแนน`,
      "คุณแน่ใจไหมที่จะปรับคะแนน"
    ).then((res) => {
      if (res === true) {
        const url = this.props.url;
        const params = {
          score,
          id: 1,
          list_id: selected_items.map((m) => m.key),
        };

        Services.submitRequest({ params, url })
          .then((res) => {
            Alert.done("ผลงานที่เลือกถูกให้คะแนนเรียบร้อย").then(() => {
              // retrieve new data
              this.retrieve();
              this.data_table_element.current.refresh();
            });
          })
          .catch((errors) => {
            console.log(errors);
          });
      }
    });
  };

  printReport = (criteria) => {
    const { keyword, filter } = criteria;
    const params = [
      ["keyword", keyword],
      ["filter", JSON.stringify(filter)],
    ];

    const url =
      axios.defaults.baseURL +
      "/report/abstractsummary?" +
      params.map((i) => i[0] + "=" + i[1]).join("&");

    window.open(url, "_blank");
  };

  printReport2 = (criteria) => {
    const { keyword, filter } = criteria;
    const params = [
      ["keyword", keyword],
      ["filter", JSON.stringify(filter)],
    ];

    const url =
      axios.defaults.baseURL +
      "/report/abstractsummary/2?" +
      params.map((i) => i[0] + "=" + i[1]).join("&");

    window.open(url, "_blank");
  };

  onSelectScore = (point, id, abstract_no) => {
    Alert.confirm(
      "คุณแน่ใจใช่ไหมที่จะให้คะแนน " +
        point +
        " คะแนน ให้กับผลงานหมายเลข " +
        abstract_no
    ).then((res) => {
      if (res === true) {
        const url = this.props.url;
        const params = { score: point, id };

        Services.submitRequest({ params, url })
          .then((res) => {
            // retrieve new data
            this.data_table_element.current.refresh();
            this.abstract_score_summary_element.current.getAbstractScoreSummary();
          })
          .catch((errors) => {
            console.log(errors);
          });
      }
    });
  };

  completeAllOfAbstract = () => {
    Alert.confirm(
      "คุณแน่ใจไหมที่จะสิ้นสุดการตรวจสอบผลงานทั้งหมด ระบบจะปรับคะแนนของผลงานที่ไม่มีคะแนนเป็น 1 คะแนนทั้งหมด และสิ้นสุดการตรวจสอบ"
    ).then((res) => {
      if (res === true) {
        this.setState({ loading: true });
        const url = "completeallofabstract";
        axios
          .post(url)
          .then((res) => {
            Alert.done(
              "ผลงานที่ไม่มีคะแนนถูกปรับเป็น 1 คะแนนทั้งหมด และสิ้นสุดการตรวจสอบ"
            );

            this.retrieve();
            this.setState({ loading: false });
            this.data_table_element.current.refresh();
          })
          .catch((ex) => {
            console.log("Error", ex);
          });
      }
    });
  };

  beforeBind = (data) => {
    let new_data = [...data];

    new_data = new_data.map((item) => {
      const key = item.key;
      const abstract_no = item.value[0];
      const value = item.score;
      const score_input = (
        <ScoreInput
          onSelectScore={(score) => this.onSelectScore(score, key, abstract_no)}
          value={value}
          round={this.state.round}
        ></ScoreInput>
      );

      item.value[4] = score_input;

      return item;
    });

    return new_data;
  };

  componentDidMount = () => {
    this.retrieve();
  };

  retrieve = () => {
    const url = "current/site";
    axios.get(url).then((res) => {
      const site = res.data;
      const abstract_period = site.abstract_periods.find(
        (f) => f.code === (site.complete_abstract ? "s2" : "s")
      );
      let subtitle = "";

      if (abstract_period && abstract_period.end_date_text)
        subtitle = `สิ้นสุดการตรวจผลงาน: ${abstract_period.end_date_text}`;

      if (site.complete_abstract === false) {
        this.setState({
          title: "การตรวจผลงานวิชาการ ครั้งที่ 1",
          subtitle: subtitle,
          is_allow_to_review: site.is_allow_to_super_review,
          round: 1,
        });
      } else if (
        site.complete_abstract === true &&
        site.complete_abstract2 === false
      ) {
        this.setState({
          title: "การตรวจผลงานวิชาการ ครั้งที่ 2",
          subtitle: subtitle,
          is_allow_to_review: site.is_allow_to_super_review,
          round: 2,
        });
      } else {
        this.setState({
          title: "สิ้นสุดการตรวจผลงาน",
          subtitle: "",
          is_allow_to_review: false,
          round: 0,
        });
      }
    });
  };

  render() {
    const { columns, url } = this.props;

    const buttons = [
      {
        is_multiple: true,
        label: '<i class="fa fa-print"></i> พิมพ์รายงาน',
        className: "btn btn-primary",
        name: "btn_print_report",
        datasource: [
          {
            label: "ผลการตรวจผลงานวิชาการครั้งที่ 1",
            onClick: this.printReport,
          },
          {
            label: "ผลการตรวจผลงานวิชาการครั้งที่ 2",
            onClick: this.printReport2,
          },
        ],
      },
      {
        is_multiple: true,
        label: '<i class="fa fa-star"></i> ให้คะแนน',
        className: "btn btn-secondary",
        name: "btn_score",
        datasource: [
          {
            label: 0 + " คะแนน",
            onClick: (criteria, selected_items) =>
              this.scoreSelectedItems(0, selected_items),
          },
          {
            label: 1 + " คะแนน",
            onClick: (criteria, selected_items) =>
              this.scoreSelectedItems(1, selected_items),
          },
          {
            label: 2 + " คะแนน",
            onClick: (criteria, selected_items) =>
              this.scoreSelectedItems(2, selected_items),
          },
        ],
      },
    ];

    return (
      <div
        className={
          this.state.loading
            ? "ibox-content border-top-0 sk-loading"
            : "ibox-content border-top-0"
        }
      >
        <Loader></Loader>
        <div className="jumbotron jumbotron-fluid mb-0">
          <div className="container">
            <h1 className="display-4">{this.state.title}</h1>
            <h3>{this.state.subtitle}</h3>
          </div>
        </div>
        {this.state.is_allow_to_review === true && (
          <button
            className="btn btn-block btn-lg btn-primary my-2"
            onClick={this.completeAllOfAbstract}
          >
            <i className="fa fa-check-square-o" aria-hidden="true"></i>{" "}
            สิ้นสุดการตรวจสอบ
          </button>
        )}
        <AbstractScoreSummary
          ref={this.abstract_score_summary_element}
        ></AbstractScoreSummary>
        <DataTable
          ref={this.data_table_element}
          buttons={buttons}
          columns={columns}
          beforeBind={this.beforeBind}
          allow_active={false}
          allow_edit={true}
          allow_insert={false}
          allow_trash={false}
          allow_print={false}
          allow_checkbox={true}
          url={url}
        ></DataTable>
      </div>
    );
  }
}

SuperReviewerIndex.defaultProps = {
  url: "",
  columns: [],
  buttons: [],
};

export default SuperReviewerIndex;
