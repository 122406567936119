import React, { Component } from "react";
import DataForm from "./../../../components/DataForm/DataForm";
import axios from "../../../axios";
import { Redirect } from "react-router-dom";
import { Services, Alert } from "../../Service/Services";
import BoxContainer from "../../../components/BoxContainer/BoxContainer";
import FormInput from "../../../components/DataForm/FormInput";
import MemberGroupMember from "./MemberGroupMember";

class MemberGroupForm extends Component {
  // Set default state
  state = {
    data: {
      user_id: null,
      members: [], // 'prefix_id', 'prefix_text', 'first_name', 'last_name', 'identity_number', 'career_number', 'career_id', 'career_text', 'nurse_number', 'certificate_no', 'email', 'online_register_type', 'section_online_id', 'avatar', 'food_type'
      document_name: "",
      document_address: "",
      document_district: "",
      document_sub_district: "",
      document_province_id: "",
      document_zip_code: "",
      receipt_user_delivery_profile_id: null,
      receipt_name: "",
      receipt_identity_no: "",
      receipt_address: "",
      receipt_district: "",
      receipt_sub_district: "",
      receipt_province_id: "",
      receipt_zip_code: "",
      receipt_address_type: "personal",
      delivery_document_type: "receipt",
      receipt_branch_code: "",
      receipt_branch_name: "",
      is_online: "1",
      company_name: "",
      company_info: null,
    },
    datasource: {
      receipt_address_type: [
        { key: "personal", label: "ออกใบเสร็จในนามบุคคล" },
        { key: "company", label: "ออกใบเสร็จในนาม รพ./หน่วยงาน/บริษัท" },
      ],
      delivery_document_type: [
        { key: "receipt", label: "ใช้ที่อยู่ในการออกใบเสร็จ" },
        { key: "other", label: "ใช้ที่อยู่อื่น" },
      ],
      register_type: [
        { key: "1", label: "Online" },
        { key: "0", label: "Onsite" },
      ],
      online_register_type: [
        { key: "f", label: "Free Theme" },
        { key: "s", label: "Selected Theme" },
      ],
      section_online: [],
      province: [],
      user: [],
      section_online: [],
      prefix_name: [],
      career: [],
      food_type: [
        { key: "normal", label: "อาหารปกติ" },
        { key: "halal", label: "อาหารฮาลาล" },
        { key: "vegetarian", label: "อาหารมังสวิรัติ" },
      ],
    },
    redirect: false,
    loading: true,
  };

  init = () => {
    const promise = new Promise((resolve) => {
      const params = {
        tables: [
          { name: "section_online" },
          { name: "external_user" },
          { name: "province" },
          { name: "prefix_name" },
          { name: "career" },
        ],
      };

      axios.post("datasource", params).then((res) => {
        const section_online = res.data.section_online.map((item) => {
          return {
            key: item.id,
            label: item.name,
            description: item.description,
          };
        });

        const external_user = res.data.external_user.map((item) => {
          return {
            key: item.id,
            label:
              (item.name ? item.name : item.username) +
              ` (${item.company ? item.company.name : " - "})`,
            company_name: item.company?.name,
            company_info: item.company ?? null,
          };
        });

        const province = res.data.province.map((item) => {
          return {
            key: item.id,
            label: item.name,
          };
        });

        const prefix_name = res.data.prefix_name.map((item) => {
          return {
            key: item.id,
            label: item.name,
          };
        });

        const career = res.data.career.map((item) => {
          return {
            key: item.id,
            label: item.name,
          };
        });

        const datasource = {
          ...this.state.datasource,
          user: external_user,
          section_online,
          province,
          prefix_name,
          career,
        };

        resolve(datasource);
      });
    });

    return promise;
  };

  retrieveData = (id, datasource) => {
    axios.get(`/member/${id}`).then((res) => {
      const data = res.data;
      const members = data.members.map((member) => {
        if (member.prefix_id === null && member.prefix_text)
          member.prefix_id = member.prefix_text;

        if (member.career_id === null && member.career_text)
          member.career_id = member.career_text;

        return member;
      });
      this.setState({
        data: {
          members: members,
          user_id: data.user_id,
          document_name: data.document_name ?? "",
          document_address: data.document_address ?? "",
          document_district: data.document_district ?? "",
          document_sub_district: data.document_sub_district ?? "",
          document_province_id: data.document_province_id ?? "",
          document_zip_code: data.document_zip_code ?? "",
          receipt_user_delivery_profile_id:
            data.receipt_user_delivery_profile_id ?? null,
          receipt_name: data.receipt_name,
          receipt_identity_no: data.receipt_identity_no,
          receipt_address: data.receipt_address,
          receipt_district: data.receipt_district,
          receipt_sub_district: data.receipt_sub_district,
          receipt_province_id: data.receipt_province_id,
          receipt_zip_code: data.receipt_zip_code,
          receipt_address_type: data.receipt_address_type,
          receipt_branch_code: data.receipt_branch_code,
          receipt_branch_name: data.receipt_branch_name,

          delivery_document_type: data.delivery_document_type,
          is_online: data.is_online === true ? "1" : "0",
          company_name: data.company_name,
        },
        loading: false,
        datasource: datasource,
      });
    });
  };

  componentDidMount = () => {
    const id = this.props.match.params.id;

    this.init().then((datasource) => {
      if (id !== undefined) {
        this.retrieveData(id, datasource);
      } else {
        this.setState({ loading: false, datasource });
      }
    });
  };

  getValue = (target) => {
    let data = this.state.data;

    const key = Object.keys(target)[0];
    const value = Object.values(target)[0];

    if (key === "user_id" && value !== data.user_id) {
      const user = this.state.datasource.user.find((f) => f.key == value);
      if (user) {
        data.company_name = user.company_name;
        data.company_info = user.company_info;
        if (!data.receipt_name) data.receipt_name = user.company_name;
      }
    }

    if (
      key === "receipt_address_type" &&
      value === "company" &&
      data.company_info !== null
    ) {
      data.receipt_identity_no = data.company_info.tax_identity_no;
      data.receipt_address = data.company_info.address;
      data.receipt_district = data.company_info.district;
      data.receipt_sub_district = data.company_info.sub_district;
      data.receipt_province_id = data.company_info.province_id;
      data.receipt_zip_code = data.company_info.zip_code;
    }

    this.setState({
      data: {
        ...data,
        ...target,
      },
    });
  };

  onSubmit = (e) => {
    const id = this.props.match.params.id;
    let data = { ...this.state.data };

    const members = data.members.map((member) => {
      if (data.is_online !== "1") {
        member.online_register_type = null;
        member.section_online_id = null;
      }

      return member;
    });

    const url = "/member";
    const redirect = true;

    const params = {
      document: JSON.stringify({
        document_name: data.document_name,
        document_address: data.document_address,
        document_district: data.document_district,
        document_sub_district: data.document_sub_district,
        document_province_id: data.document_province_id,
        document_zip_code: data.document_zip_code,
        receipt_user_delivery_profile_id: data.receipt_user_delivery_profile_id,
        receipt_name: data.receipt_name,
        receipt_identity_no: data.receipt_identity_no,
        receipt_address: data.receipt_address,
        receipt_district: data.receipt_district,
        receipt_sub_district: data.receipt_sub_district,
        receipt_province_id: data.receipt_province_id,
        receipt_zip_code: data.receipt_zip_code,
        receipt_address_type: data.receipt_address_type,
        receipt_branch_code: data.receipt_branch_code,
        receipt_branch_name: data.receipt_branch_name,

        delivery_document_type: data.delivery_document_type,
      }),
      members: JSON.stringify(members),
      is_online: data.is_online,
      id: id,
      user_id: data.user_id,
    };

    this.setState({ loading: true });

    Services.submitRequestWithFile({ params, url })
      .then((res) => {
        this.setState({ redirect, loading: false });
      })
      .catch((errors) => {
        this.setState({ errors, loading: false });
      });
  };

  getRedirect = () => {
    const redirect = this.state.redirect;

    if (redirect) {
      const url = this.props.url;

      return <Redirect to={url} />;
    }

    return;
  };

  getFields = () => {
    const id = this.props.match.params.id;

    var fields = [
      {
        label: "ผู้ใช้งาน",
        name: "user_id",
        required: true,
        type: "ddl",
        minimumInputLength: 1,
        value: this.state.data.user_id,
        datasource: this.state.datasource.user,
      },
      {
        label: "ชื่อสถาบัน",
        name: "company_name",
        type: "label",
        required: false,
        value: this.state.data.company_name,
      },
      {
        label: "ประเภทการลงทะเบียน",
        name: "is_online",
        type: "radio",
        value: this.state.data.is_online,
        datasource: this.state.datasource.register_type,
      },
    ];

    return fields;
  };

  getMemberValue = (members) => {
    const new_data = { ...this.state.data };
    new_data.members = members;

    this.setState({
      data: new_data,
    });
  };

  render() {
    const fields = this.getFields();
    const data = this.state.data;
    const redirect = this.getRedirect();
    const url = this.props.url;
    const id = this.props.match.params.id;
    let buttons = [];

    let receipt_fields = [
      {
        label: "ที่อยู่",
        name: "receipt_address",
        value: this.state.data.receipt_address,
        type: "text",
        required: true,
      },
      {
        label: "แขวง/ตำบล",
        name: "receipt_sub_district",
        value: this.state.data.receipt_sub_district,
        type: "text",
        required: true,
        col: 6,
      },
      {
        label: "เขต/อำเภอ",
        name: "receipt_district",
        value: this.state.data.receipt_district,
        type: "text",
        required: true,
        col: 6,
      },
      {
        label: "จังหวัด",
        name: "receipt_province_id",
        value: this.state.data.receipt_province_id,
        type: "ddl",
        required: true,
        col: 6,
        datasource: this.state.datasource.province,
      },
      {
        label: "รหัสไปรษณีย์",
        name: "receipt_zip_code",
        value: this.state.data.receipt_zip_code,
        type: "text",
        required: true,
        col: 6,
      },
    ];

    const document_fields = [
      {
        label: "ชื่อผู้รับ",
        name: "document_name",
        value: data.document_name,
        type: "text",
        required: true,
      },
      {
        label: "ที่อยู่",
        name: "document_address",
        value: data.document_address,
        type: "text",
        required: true,
      },
      {
        label: "แขวง/ตำบล",
        name: "document_sub_district",
        value: data.document_sub_district,
        type: "text",
        required: true,
        col: 6,
      },
      {
        label: "เขต/อำเภอ",
        name: "document_district",
        value: data.document_district,
        type: "text",
        required: true,
        col: 6,
      },
      {
        label: "จังหวัด",
        name: "document_province_id",
        value: data.document_province_id,
        type: "ddl",
        required: true,
        datasource: this.state.datasource.province,
        col: 6,
      },
      {
        label: "รหัสไปรษณีย์",
        name: "document_zip_code",
        value: data.document_zip_code,
        type: "text",
        required: true,
        col: 6,
      },
    ];

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm
          fields={fields}
          onSubmit={this.onSubmit}
          url={url}
          getValue={this.getValue}
          buttons={buttons}
        >
          <MemberGroupMember
            members={this.state.data.members}
            datasource={this.state.datasource}
            is_online={this.state.data.is_online}
            getValue={this.getMemberValue}
            loading={this.state.loading}
          />
          <h3>ที่อยู่ในการออกใบเสร็จ</h3>
          <div className="row">
            <div className="col-12">
              <FormInput
                field={{
                  label: "การออกใบเสร็จ",
                  name: "receipt_address_type",
                  type: "radio",
                  value: this.state.data.receipt_address_type,
                  datasource: this.state.datasource.receipt_address_type,
                }}
                getValue={this.getValue}
              />
            </div>
            {data.receipt_address_type === "company" && (
              <>
                <div className="col-6">
                  <FormInput
                    field={{
                      label: "ชื่อในการออกใบเสร็จ",
                      type: "text",
                      name: "receipt_name",
                      value: this.state.data.receipt_name,
                    }}
                    getValue={this.getValue}
                  />
                </div>
                <div className="col-6">
                  <FormInput
                    field={{
                      label: "เลขประจำตัวผู้เสียภาษี",
                      type: "text",
                      name: "receipt_identity_no",
                      value: this.state.data.receipt_identity_no,
                    }}
                    getValue={this.getValue}
                  />
                </div>
                <div className="col-6">
                  <FormInput
                    field={{
                      label: "รหัสสาขา",
                      type: "text",
                      name: "receipt_branch_code",
                      value: this.state.data.receipt_branch_code,
                    }}
                    getValue={this.getValue}
                  />
                </div>
                <div className="col-6">
                  <FormInput
                    field={{
                      label: "ชื่อสาขา",
                      type: "text",
                      name: "receipt_branch_name",
                      value: this.state.data.receipt_branch_name,
                    }}
                    getValue={this.getValue}
                  />
                </div>
              </>
            )}
            {receipt_fields.map((field) => (
              <div
                key={field.name}
                className={field.col ? `col-${field.col}` : "col-12"}
              >
                <FormInput field={field} getValue={this.getValue} />
              </div>
            ))}
          </div>
          <h3>ที่อยู่ในการจัดส่งเอกสาร</h3>
          <FormInput
            field={{
              type: "radio",
              name: "delivery_document_type",
              value: this.state.data.delivery_document_type,
              datasource: this.state.datasource.delivery_document_type,
            }}
            getValue={this.getValue}
          />
          <div className="row">
            {data.delivery_document_type !== "receipt" &&
              document_fields.map((field) => (
                <div
                  key={field.name}
                  className={field.col ? `col-${field.col}` : "col-12"}
                >
                  <FormInput field={field} getValue={this.getValue} />
                </div>
              ))}
          </div>
        </DataForm>
      </BoxContainer>
    );
  }
}

MemberGroupForm.setDefaultProps = {
  url: "",
};

export default MemberGroupForm;
